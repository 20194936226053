input#fname {
  border: none;
  background: #f6f6f6;
}

.material-symbols-outlined {
  font-size: inherit;
}
.profiledetailscls p {
  margin-bottom: 0px;
}

img.uploadimage {
  width: 110px;
  border-radius: 50%;
  border: 1px solid #efefef;
  object-fit: cover;
  height: 110px;
  object-fit: cover;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #197d9f !important;
  border-color: #197d9f;
}

i.material-icons.edit.mt-1 {
  cursor: pointer;
}

.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;

  border: 1px solid #cacaca;
  border-radius: 5px;
  line-height: 25px;
  height: 38px;
  width: 394px;
  outline: none;
}

textarea#textarea.txarescls {
  border: none;
  background: #f6f6f6;
  height: 300px;
}

.pluseadd {
  color: #197d9f;
  font-size: 13px;
}

h4.user:hover {
  text-decoration: underline;
}

.textcls {
  font-size: 13px;
}

.outlinebtn {
  border-radius: 21px !important;
  border: 1px solid #197d9f !important;
  background: #fff;
  color: #197d9f;
  padding: 9px 28px;
  font-weight: 700;
}

.status.status-blue {
  background-color: #d7f5ff;
  color: #197d9f;
}

.status {
  display: inline-block;
  padding: 4px 11px;
  border-radius: 4px;
  font-weight: 400;
  background-color: #e4e4e4;
}

.Profilehedding.mt-3 {
  font-size: 18px;
  font-weight: 700;
  color: #000;
}

.emailclss {
  font-size: 13px;
  font-weight: 600;
  color: #0a5d79;
  word-break: break-all;
}

.profileheddingcls {
  font-size: 14px;
  font-weight: 400;
  color: #000000c2 !important;
  max-width: 300px;
}

.View_Case {
  font-weight: 700;
}

.profileresulhead {
  font-size: 17px;
  font-weight: 700;
  color: #245b65;
}

.span_high_class.high_risk {
  color: #ba0036 !important;
  background: #f9e4e9 !important;
}

.high_risk {
  display: inline-block;
  padding: 4px 11px !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.span_low_risk.span_low_risk {
  background: #d8fffe;
  color: #22a59e;
}

.high_risk.span_Moderate_risk {
  color: #8d751a;
  background: #fff7d7;
}

a.text-primary.view_Case {
  font-weight: 700;
}

.cancelbtn {
  border: 1px solid #197d9f;
  color: #197d9f;
  border-radius: 100px;
  padding: 13px 6rem;
  background: #fff;
}

.sidefont {
  font-size: 14px;
  font-weight: 500;
}

.rightfont {
  font-size: 14px;
}

.savebtn {
  background: #acacac;
  color: #fff;
  border-radius: 100px;
  border: none;
  padding: 1rem 6rem;
}

.buttonclass {
  border-radius: 30px;
  width: 150px;
}

.buttonSave {
  border-radius: 30px;
  width: 150px;
  background: gray;
}

.addbtn {
  border-radius: 21px;
}
// Bids
.rejectedbid {
  color: #ff0000cf !important ;
  font-size: 12px;
  font-weight: 500;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
}
.pendingbid {
  color: orange !important;
  font-size: 12px;
  font-weight: 500;

  text-align: center;
}
.awardedbid {
  color: #4a4c9f !important;
  font-size: 12px;
  font-weight: 500;

  text-align: center;
}
.acceptedbid {
  color: green !important;
  font-size: 12px;
  font-weight: 500;
}