.sendclss {
  color: #fff !important;
}

form.p-5.rounded.shadow {
  height: 100%;
}

.loginimg {
  width: 100%;
}

form.p-3.rounded.shadow {
  border-top: none;
}

img.loginimg.w-100 {
  height: 100%;
  object-fit: cover;
}

img.logimg {
  width: 100%;
  max-width: 167px;
  max-height: 100px;
  object-fit: cover;
}

.login-wrapper {
  .mainfromclss {
    max-width: 900px;
  }
}
img.logo {
  width: 300px;
  height: auto;
}
.logo_img {
  padding: 0rem 3rem;
}
.banner_img {
  // background-image: url(../../../public/assets/img/login_Img.png);
  height: 100vh;
  width: 100%;
  // background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 4rem 0rem;
}
.loginLeftImg h3 {
  position: absolute;
  bottom: 10px;
  font-size: 36px;
  font-weight: 600;
  line-height: 61px;
  color: #fff;
  padding: 4rem 2rem;
}

p.accopunt {
  text-align: right;
  right: 20px;
  padding: 1rem 1rem;
  font-size: 16px;
  font-weight: 400;
}
p.accopuntt {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  color: #84818a;
  word-spacing: 3px;
  margin: 0;
}
a.sign_up {
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
}
.forget a {
  color: #3c559b;
  font-size: 14px;
}
h3.text-left.lgtext {
  font-weight: 600;
  font-size: 24px;
  color: #3b549a;
}
.bginput {
  border-radius: 4px !important;
  border: 1px solid #e8e8e8;
  height: 39px;
}
a.text-primary:hover,
a.text-primary:focus {
  color: #00a5d9 !important;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
}
.text-primary {
  color: #00a5d9 !important;
  font-size: 14px;
  font-weight: 400;
}

.title-around-span {
  position: relative;
  text-align: center;
  color: #484848;
  font-size: 14px;

  &:before {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    background-color: #e8e8e8;
  }

  span {
    position: relative;
    z-index: 1;
    padding: 0 15px;
    background: #ffff;
  }
}

.google_logo {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}
img.gogle_img {
  width: 30px;
  height: 30px;
  margin: 0px 5px;
}
img.gogle_imgs {
  width: 25px;
  height: 24px;
  margin: 0px 5px;
}

a.google_heading {
  font-size: 14px;
  font-weight: 600;
  color: #202020;
  &:hover {
    text-decoration: none;
  }
}

p.para_forget {
  font-size: 14px;
  font-weight: 400;
  color: #484848;
  line-height: 20px;
}
p.para_forget_new {
  font-size: 14px;
  font-weight: 400;
  color: #484848;
  text-align: left;
  width: 100%;
  max-width: 300px;
}
