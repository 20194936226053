.view_img1 {
  width: 55px;
  height: 55px;
  object-fit: cover;
}
.padbottom {
  margin-bottom: 115px;
}
.heightaccordian {
  // max-height: 260px;
  height: 260px;
  overflow: auto;
}
.accordileft {
  padding-right: 4px;
}
@media (min-width: 1200px) {
  .bannerleft {
    min-width: 662px;
  }
}
@media (min-width: 1300px) {
  .bannerleft {
    min-width: 735px;
  }
  .padbottom {
    margin-bottom: 140px;
  }
}
