.imagethumbWrapper{
    position: relative;
    display: inline-block;
    .fa-times{
        position: absolute;
        right: 3px;
        line-height: 20px;
        text-align: center;
        width: 20px;
        top: 3px;
        cursor: pointer;
        font-size: 10px;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
   
}
.imagethumbWrapper.product-images img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius:3px; 
}
.imagethumbWrapper.product-images {
    margin: 0 11px 0 0;
}
.imagesRow {
    margin: 10px 0 0 0;
}