.dropdown-menu.shadow.bg_hover.leftAlignDrop {
    // left: 0 !important;
    width: fit-content;
    max-height: 200px;
    overflow: auto;
}

.removeBg{
    &:hover {
        background: #fff !important;
        border: 1px solid #ced4da !important;
        color: #495057 !important;
        border-radius: 50px;
    }
    &.invalid{
        border-color: red !important;
    }
}

.custm-dropdown.btn-primary {
    border: 1px solid #DCDCDC !important;
    background: transparent !important;
    color: #495057 !important;
    position: relative;
    text-align: left;
}
.custm-dropdown.dropdown-toggle::after {
    right: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
 
}
.custm-dropdown .addDropdown .dropdown-menu {
    left: initial !important;
    right: 0 !important;
    top: 45px !important;
    transform: none !important;
    width: 100%;
}
.dropdown-item.active, .dropdown-item:active {
    color: #fff !important;
    text-decoration: none;
    background-color: #3E549D;
}
.custm-dropdown .dropdown-menu a:hover {
    color: #fff !important;
}