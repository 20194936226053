@media (max-width: 1440px) {
  .banner-img img {
    width: 100%;
    max-width: 662px;
  }
  .home-searchbar .input-group-text {
    width: 20%;
  }
  .home-searchbar input {
    width: 80%;
  }
  .banner-right-section {
    padding: 100px 0;
  }
}

@media (max-width: 1399px) {

  .submit-btn {
    width: 20%;
  }
  .blue-bg {
    top: -58px;
  }
  .logos-left-sect p {
    width: 400px;
  }
}

@media (max-width: 1199px) {
  h1 {
    font-size: 33px;
    line-height: 43px;
  }
  .banner-img img {
    width: 100%;
    max-width: 545px;
  }
  .banner-right-section {
    padding: 65px 0;
  }
  .home-searchbar span#basic-addon1 {
    font-size: 12px;
    padding: 0px 7px;
  }
  .stats-inner li {
    padding: 40px 20px;
  }
  .stats-inner {
    flex-wrap: wrap;
  }
}

@media (max-width: 991px) {
  .home-searchbar {
    flex-direction: column;
  }
  .home-searchbar .input-group {
    width: 100%;
  }
  .submit-btn {
    width: 100%;
  }
  .banner-img img {
    width: 100%;
    max-width: 440px;
  }
  .home-searchbar .input-group {
    width: 100%;
    padding: 10px 0;
  }
  h1 {
    font-size: 26px;
    line-height: 33px;
  }
  .banner-right-section p {
    color: #5e6670;
    font-size: 15px;
  }
  .banner-right-section {
    padding: 17px 0;
  }
  .home-searchbar span#basic-addon1 {
    font-size: 12px;
    padding: 0 7px 0 0;
  }
  .home-searchbar .input-group-text {
    width: 12%;
  }
  .home-searchbar input {
    width: 88%;
  }
  .border-btm {
    display: block;
  }
  .header-btns {
    margin: 0 !important;
  }
  .blue-bg {
    display: none;
  }
  .testimonial-inner {
    margin: 40px 0 0 0;
    background: #0155a5;
    padding: 30px;
    border-radius: 20px;
  }
  .testimonial-content {
    margin: 6px 0;
  }
  .stats-inner li {
    width: 44%;
    margin: 12px 0;
  }
  .contact-us-form {
    margin: 25px 0 0 0;
  }

  .banner-right-section h1 {
    width: 100%;
  }
  .banner-right-section p {
    width: 100%;
  }

  .logos-left-sect {
    margin-bottom: 30px;
  }

  .logos-images img {
    width: 100%;
    max-width: 180px;
  }
  .logos-section::before {
    top: 50%;
    transform: translateY(-50%);
  }
  .job-load {
    flex-direction: column;
    align-items: flex-start;
  }
  .job-price {
    margin-left: 0;
  }
  .brokerage_details {
    margin-bottom: 30px;
  }
  .logos-left-sect {
    justify-content: flex-start;
  }
  .section-padding {
    padding: 50px 0;
  }
  .job-load.job-time-absolute {
    padding: 10px;
  }

  // Manjinder Css
    .logos-images {
      position: static;
      width: 100%;
    }
      .logo-end {
        margin-left: 0 !important;
  }
  .logo-end li {
    width: auto!important;
}
}

@media (max-width: 767px) {
  .reversecol{
    flex-direction: column-reverse;
  }
  .padbottom{
    margin-bottom: 7px !important;
  }
  .main-sidebar.d-md-block {
    position: fixed;
    left: -1px;
    z-index: 99;

  }
  .main{
    padding-top: 2rem !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    margin-left: auto !important;
    padding-bottom: 2rem !important;
  }
  .input_div1 {
    margin-bottom: 1.3rem;
  }
  .banner-img {
    position: unset;
  }
  h1 {
    font-size: 32px;
    line-height: 43px;
  }
  .home-searchbar .input-group {
    width: 100%;
    padding: 5px 0 20px 0;
}

  .logos-images ul li {
    width: 48%;
    padding: 18px 0;
  }
  .brokerage_details {
    margin-bottom: 30px;
  }
  .play-btn img {
    width: 40px;
    height: 4%;
  }
  h3 {
    font-size: 28px;
  }
  .brokerage_heading {
    width: 100%;
    font-size: 38px;
    line-height: 47px;
    margin: 0 0 20px 0;
  }
  .serviceimges {
    width: 100%;
}

}

@media (max-width: 576px) {
  .stats-inner li {
    width: 100%;
  }
  h2 {
    font-size: 27px;
  }
  .testimonial-inner {
    padding: 16px;
  }
  .gradient-bg {
    padding: 15px;
  }
  .logos-left-sect {
    width: 100%;
  }
  .logos-left-sect h2 {
    font-size: 38px;
    line-height: 47px;
  }
  .logos-images ul li {
    width: 100%;
  }
  p {
    font-size: 14px;
  }
}

@media (max-width: 1200px) {
  // jobs css
  .google-search {
    width: 80%;
  }

  .manage_child_flex {
    margin-top: 40px;
  }
}
@media (max-width: 991px) {
  .trending_col {
    margin-bottom: 15px;
  }
  .trending_col .story_desc {
    padding-right: 0px;
  }
  // Jobs css
  .job-searchbar {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin: 0 0 20px 0;
  }
  .contact_hero {
    height: 445px;
  }
  .contact_hero_parent {
    padding-left: 0px;
  }
  .contact_heading {
    font-size: 50px;
    line-height: 52px;
  }
  .support_col {
    margin-top: 0px;
  }
  .assistance_card .dark-btn {
    width: 140px;
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .onelogo{
    width: 106px;
    object-fit: cover;
  }
  .wrapflex{
    flex-wrap: wrap;
    gap: 10px 0px;
    display: flex;
  }
  .m_images {
    width: 200px;
  }
  .grow_parent {
    height: 310px;
  }
  .grow_desc {
    font-size: 18px;
    margin-bottom: 0;
  }
  .grow_desc {
    color: #232323;
    font-weight: 400;
    font-size: 18px;
  }
  .grow-header {
    font-size: 40px;
    margin-top: 10px;
    line-height: 40px;
  }
  // Jobs css
  .jobs-listing {
    margin-top: 0px;
  }
  .contact_hero {
    height: 425px;
  }

  .contact_heading {
    font-size: 42px;
    line-height: 45px;
  }
  .support_col {
    margin-top: 0px;
  }
  .growth_header {
    font-size: 34px;
    margin-bottom: 12px;
  }
  .support_heading {
    font-size: 35px;
  }
  .support_child {
    color: #111827;
    font-size: 14px;
    margin-bottom: 0px;
  }
  .support_desc {
    margin-top: 10px;
    font-size: 16px;
    line-height: 32px;
    padding-right: 0px;
  }
  .growth_desc {
    font-size: 18px;
  }
  .fullit{
    width: 106%;
  }

}
@media(max-width:515px){
  .google-search {
    width: 60%;
}
}

@media (max-width: 480px) {
  // Signup css
  form.centerLogin h3 {
    font-size: 20px;
  }
  form.centerLogin p.accopunt {
    font-size: 13px;
  }
  .RSPBprogressBar {
    width: 208px;
  }
  // About page
  .about_hero {
    height: 260px;
  }
  h4.about_header {
    font-size: 32px;
  }
  .story_header {
    font-size: 20px;
    margin-bottom: 5px;
    line-height: 28px;
  }
  // .m_images {
  //   width: 100%;
  //   height: auto;
  // }
  .story_desc {
    font-size: 14px;
    line-height: 25px;
    margin-bottom: 25px;
  }
  .best_images {
    height: auto;
  }
  .best_right {
    font-size: 20px;
  }
  .right_desc {
    font-size: 10px;
    line-height: 18px;
  }
  .grow_parent {
    height: 310px;
  }
  .grow_desc {
    font-size: 15px;
    margin-bottom: 0;
  }
  .grow-header {
    font-size: 22px;
    margin-top: 10px;
  }
  // Jobs css
  .jobs_child-flex {
    flex-wrap: wrap;
    gap: 10px;
  }
  .about_div {
    margin-top: 40px;
  }
  .manage_child_flex {
    gap: 0;
    flex-direction: column;
  }
  .flex_assests {
    display: flex;
    flex-direction: column;
  }
  .contact_hero {
    height: 285px;
  }
  .contact_hero_parent {
    padding-top: 70px;
  }
  .contact_heading {
    margin-top: 15px;
    font-size: 27px;
    width: auto;
    line-height: 35px;
  }
  .support_row {
    margin-top: 30px;
  }

  .support_heading {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .support_child {
    font-size: 10px;
    font-weight: 200;
    margin-bottom: 0px;
  }
  .support_desc {
    margin-top: 7px;
    font-size: 12px;
    line-height: 20px;
  }
  .support_card {
    padding: 22px 22px;
  }
  .Card_heading {
    font-size: 22px;
  }
  .card_desc {
    font-size: 15px;
  }
  .growth_header {
    font-size: 26px;
    margin-bottom: 20px;
  }
  .growth_desc {
    font-size: 17px;
  }
  .job-searchbar button.btn-cancel {
    width: 100%;
    padding: 9px 0;
  }
  .job-searchbar input.form-control {
    padding-right: 0;
    margin-right: 0 !important;
  }
  .pagination{
    flex-wrap: wrap;
    gap: 6px 0px;

  }
  .paginationWrapper.main-pagination {
    flex-direction: column;
    gap: 10px 0px;
  }
  .paginationWrapper.main-pagination span {
    margin: 0 0 12px 0;
  }
  .logos-left-sect p {
    width: auto;
  }
  .logos-left-sect h2 {
    font-size: 28px;
    line-height: 35px;
  }
  .common-title2 h2{
    font-size: 20px;
    line-height: 32px;
  }
  .brokerage_heading {
    font-size: 30px;
    line-height: 40px;
}
.accordion-button {
  font-size: 15px;
}
.paraCls{
  padding-bottom: 10px !important;
}
.sales_section {
  max-height: 150px !important;

}
.jobs-location-main {
  flex-wrap: wrap;
  gap: 10px;
}
h2.mb-1.mainHeading_cls , .dsh_hedding {
font-size: 28px !important;
}
.datepickerdropdown .btn{
  font-size: 10px !important;
}
.main-navbar {
  padding-left: 130px !important;
}
.jobs-modal .modal-dialog {
  width: 300px !important;
}

}
@media (max-width:544px){
  .notifilayout {
    min-width: 270px !important;
    left: -177px !important;
    max-width: 270px !important;
    top: 48px !important;
}
.onelogo{
  width: 90px;
  object-fit: cover;
}
.messagep {
  line-height: 18px !important;
  font-size: 10px !important;
  line-height: 12px !important;
}
.username-head b {
  font-size: 11px;
}
.username-head p {
  font-size: 10px;
}
.menu-title {
  font-size: 13px;
}
}
@media (min-width: 992px) and (max-width: 1199px) {
  .m_images {
    height: auto;
    width: 435px;
  }
  .best_cards {
    padding: 10px 12px;
  }
  .short_cards {
    height: 30px;
  }
  .cards_title {
    margin-left: 10px;
    font-size: 9px;
  }
  .list_box {
    height: 355px;
}
}
@media (max-width:400px){
.list_box {
  height: 340px !important;
}
}

@media (max-width:535px){
  .logos-images ul {
    flex-direction: column;
  }
}
@media (max-width:575px){
  .list_box{
    height: 275px;
  }
}
@media only screen and (min-width:535px) and (max-width:767px){
  .logos-images ul {
    flex-wrap: nowrap; 
   gap: 25px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .assistance_card {
    padding: 20px 10px;
  }
}
@media only screen and (min-width:575px) and (max-width:767px){
  .list_box {
    height: 375px;
  }
}
