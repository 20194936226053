.pac-container{
    z-index: 9999;
    max-width: 500px;
    position: absolute;
}
.pac-container, .pac-item {
    max-width: 500px;
    position: relative;
}
.pac-container:after{
    content:none !important;
}