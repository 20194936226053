.extramargin{
    margin: 8rem 0;
}
.privacyheader{
    .descrition_p{
        font-size: 16px;
        line-height: 25px;
        color: #000000eb;
        font-weight: 400;
    }
    .inner_span {
        font-weight: 400;
        font-size: 17px;
        padding-left: 15px;
    }
    h4{
        margin-top: 60px;
    }
    h5{
        display: flex;
        gap: 30px;
        margin-bottom: 12px;
    }
    span.first_span {
        min-width: 192px;
        display: inline-block;
        margin-bottom: 12px;
        font-size: 18px;
        font-weight: 600;
    }
}

@media(max-width:767px){
    .privacyheader{
    h5{
        display: block;
    }
    span.first_span {
         min-width: auto;
    }
}
}
@media(max-width:480px){
    .privacyheader{
    h4{
        font-size: 24px;
    }
    .descrition_p {
        font-size: 15px;
    }
    h5{
        font-size: 18px;
    }
}
}