// .vertual_container {
//     max-width: 1309px;
// }

.border_userDate {
  border: 1px solid #dbdbdb;
  border-radius: 20px;
  // height: 100%;
}
.sticky_search.mb-3 {
  position: sticky;
  top: 0px;
  background: #fff;
  padding: 10px;
  z-index: 9;
}
img.img_rabot {
  width: 180px;
  border-radius: 20px;
  object-fit: cover;
}
img.user_imgs {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
}
// job search
.bg_blue_port {
  border: none;
}
.top_box {
  border: 1px solid #dedede;
  border-radius: 20px;
  padding: 16px 25px;
  height: 137px;
}
.link_cls {
  color: #000 !important;
}

p.underline_cls {
  font-size: 15px;
  font-weight: 500;
}

p.underline_cls {
  text-decoration: underline;
}
.link_cls:hover {
  color: #000 !important;
}
.daycls {
  font-size: 22px;
  font-weight: 600;
}

.messagcls {
  font-size: 33px;
  font-weight: 600;
  color: #000000d9;
}

.gray_cls {
  color: #000000b5;
}
p.gay_title {
  color: gray;
  font-size: 13px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  // color: #00000091;
  // background-color: white !important;
  .listcls:hover {
    border-radius: 0px !important;
    color: #000000ad !important;
    background-color: none !important;
  }

  background: none;
  color: #4ba5d9;
  box-shadow: none;
  border-bottom: 3px solid #4ba5d9;
  border-radius: 0px;
}

i.fa.fa-check-circle {
  color: #4ca5de;
}

.nav-link .tabcls_ver :active {
  color: #00000091;
  background-color: white !important;
}

a.nav-link.active:hover {
  color: #4ba5d9 !important;
}

.maindiv_boder {
  border: 1px solid #8080804a;
  border-radius: 20px;
}
.jobsearch_box:hover {
  background: #a1d3e42e;
}

span.hourly_rate {
  font-weight: 500;
}
i.fa.fa-star.Rating.mr-1 {
  color: orange;
  font-size: 13px;
}

.sidebox {
  border-radius: 20px;
}

.lessthan_cls {
  font-size: 13px;
  font-weight: 500;
}
.description_cls {
  font-size: 14px;
}
span.Proposals_cls {
  font-weight: 300;
  color: #000000a6;
}

//

.var_search {
  font-size: 20px;
}

// acordian css
button.btn.btn-link.btn-block.text-left.colaps_cls.collapsed {
  color: #000;
  font-size: 15px;
}

button.btn.btn-link.btn-block.text-left.colaps_cls.collapsed:hover {
  color: #000 !important;
  font-size: 15px;
}
h3.complete_profile {
  font-size: 13px;
  color: #4ba5d9;
}

button.btn.btn-link.btn-block.text-left.colaps_cls {
  color: #000;
}
.colaps_cls:hover {
  color: #000 !important;
}

button.btn.btn-link.btn-block.text-left.colaps_cls:hover {
  color: #000;
}

.cordian_header {
  background-color: #fff !important;
}

b.profilename {
  font-size: 18px;
}
i.fa.fa-caret-down {
  font-size: 19px;
}
.appybtn {
  padding: 11px 5rem;
  width: 100%;
}
button.btn.btn-outline-primary.mb-3.appybtn {
  display: inline-flex;
  justify-content: center;
}

// button.btn.btn-outline-primary.mb-3.appybtn {
//   padding: 9px 4rem;
// }
// .progressbar {
//   border-radius: 12px;
//   height: 7px;
//   background: #4ba5d9;
// }

// .progressbar_main {
//   border-radius: 12px;
//   height: 7px;
// }

h3.closer_heding_cls {
  font-size: 14px;
  color: #4ba5d9;
}
// end

// gourav css

.range_numbers .numbers {
  color: #666666;
  font-size: 12px;
  font-weight: 600;
  margin: 0px;
}

.range_distance .range-slider {
  height: 4px;
  margin-top: 3px;
}

.range_distance .range-slider .range-slider__thumb {
  width: 10px;
  height: 10px;
  background-color: #47569d !important;
}
.range_distance .range-slider .range-slider__range {
  background-color: #47569d !important;
}

.default_show {
  width: 100%;
}
