.loginimg {
  width: 100%;
}
.arrow_right {
  font-size: 18px;
  margin-right: 5px;
}
a.right_home:hover {
  color: #000 !important;
  text-decoration: none;
}
a.right_home {
  display: flex;
  align-items: center;
  font-size: 14px;
  background: #3f559e;
  border: 1px solid;
  padding: 5px 10px;
  border-radius: 32px;
  color: #fff;
  margin: 15px;
  width: 100px;
  position: absolute;
}
button.btn.btn-outline.socialBtn img {
  width: 16px;
}
.login_img {
  display: none !important;
}
.dark-btn:hover {
  border: 1px solid #3f559e !important;
  color: #3f559e;
  background: transparent;
}
.borderCls {
  height: 1px;
  width: 100%;
  // background: #DCDCDC;
  text-align: center;
}
span.or {
  position: relative;
  top: -12px;
  background: #fff;
  padding: 2px;
  color: #898989;
}
form.p-3.rounded.shadow {
  border-top: none;
}

.google_id {
  box-shadow: 0px 0px 5px #80808059;
  width: 52px;
}

img.loginimg.w-100 {
  height: 100%;
  object-fit: contain;
}

img.logimg {
  width: 100%;
  max-width: 167px;
  max-height: 100px;
  object-fit: contain;
}

.login-wrapper {
  .mainfromclss {
    max-width: 900px;
  }
}
img.logo {
  width: 300px;
  height: auto;
}
.logo_img {
  padding: 0rem 3rem;
}
.banner_img {
  // background-image: url(../../../public/assets/img/loginimg.png);
  height: 100vh;
  width: 100%;
  // background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 4rem 0rem;
}
.loginLeftImg h3 {
  position: absolute;
  bottom: 10px;
  font-size: 36px;
  font-weight: 600;
  line-height: 50px;
  color: #fff;
  padding: 4rem 2rem;
}

p.accopunt {
  text-align: right;
  // position: absolute;
  // right: 55px;
  // padding: 4rem 1rem;
  font-size: 14px;
  font-weight: 400;
  // top: 31px !important;
}
p.accopunts {
  position: absolute;
  padding: 0rem 1rem;
  font-size: 13px;
  font-weight: 400;
  width: 100%;
  text-align: left;
  bottom: 0;
  max-width: 454px;
  color: #84818a;
  word-spacing: 3px;
}
a.sign_up {
  text-decoration: none;
  font-size: 16px;
  color: #3b549a;
}
.forget a {
  color: #3c559b;
  font-size: 14px;
}
h3.text-left.lgtext {
  font-weight: 600;
  font-size: 24px;
  color: #3b549a;
}
.bginput {
  border-radius: 4px !important;
  border: 1px solid #e8e8e8;
  height: 39px;
}
a:hover {
  // color: #27B7F0 !important;
  color: #3f559e !important;
}
a.text-black {
  color: #000 !important;
  text-decoration: none;
}
a.text-primary:hover,
a.text-primary:focus {
  color: #27b7f0 !important;
  text-decoration: none;
}
.text-primary {
  color: #27b7f0 !important;
  font-size: 14px;
}

.title-around-span {
  position: relative;
  text-align: center;
  color: #484848;
  font-size: 14px;

  &:before {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    background-color: #e8e8e8;
  }

  span {
    position: relative;
    z-index: 1;
    padding: 0 15px;
    background: #ffff;
  }
}

.google_logo {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}
img.gogle_img {
  width: 30px;
  height: 30px;
  margin: 0px 5px;
}
img.gogle_imgs {
  width: 25px;
  height: 24px;
  margin: 0px 5px;
}
a.btn.btn-outline.google_id img {
  width: 16px;
  margin-right: 0px;
}
.google_id {
  border: 1px solid #e8e8e8;
  width: 173px;
  height: 48px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}
.google_heading {
  font-size: 16px;
  font-weight: 600;
  color: #202020;
}
.google_id {
  width: 178px;
  border: 1px solid #e8e8e8;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.checkBox {
  position: relative;
  top: 2px;
}
.clickBox {
  color: #898989;
  font-size: 12px;
}
.sign_up:hover {
  color: #3f559e !important;
}
.right_side {
  background-color: #f8f8f8;
  height: 100%;
  padding: 20px 30px;
  border-radius: 20px;
  border: 1px solid #3f559e;
}
.logo_image img {
  width: 50px;
  margin: 0 auto;
  display: block;
}
.doc-add-inner {
  position: relative;
}
.add-doc-plus {
  background: #e5e5e5;
  width: 100%;
  height: 120px;
  display: flex;
  text-align: center;
  border: 1px dashed #efefef;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  position: absolute;
  flex-direction: column;
}
.add-doc-plus img {
  opacity: 0.2;
  width: 30px;
}
.doc-add-inner input {
  opacity: 0;
  width: 100%;
  height: 120px;
}
.inputWrapper {
  position: relative;
}
.progressbar {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.progressbar li {
  list-style: none;
  padding: 20px;
  background: #d1d1d1;
  color: #999;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.progressbar li::after {
  position: absolute;
  width: 35px;
  content: " ";
  height: 2px;
  background: #999999;
  display: block;
  right: -35px;
}
.progressbar li:last-child::after {
  display: none;
}
.active-circle {
  background: #3f559e !important;
  color: #fff !important;
}
.active-circle::after {
  color: #3f559e !important;
}
.dark-btn-width {
  width: 146px;
  margin: 30px 0 0 auto;
}
@media only screen and (min-width: 1001px) and (max-width: 1060px) {
  img.logo {
    width: 200px !important;
    height: auto;
  }
  .logo_img {
    padding: 0rem 2rem;
  }
  .loginLeftImg h3 {
    position: absolute;
    bottom: 10px;
    font-size: 29px !important;
    line-height: 53px !important;
    color: #fff;
    padding: 2rem 2rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
  img.logo {
    width: 200px !important;
    height: auto;
  }
  .logo_img {
    padding: 0rem 2rem;
  }
  .loginLeftImg h3 {
    position: absolute;
    bottom: 10px;
    font-size: 29px !important;
    line-height: 35px;
    color: #fff;
    padding: 2rem 2rem;
  }
}

@media (max-width: 767px) {
  .banner_img {
    display: none;
  }
  .logo_image {
    margin: 0rem 0rem 0rem 0rem;
  }

  img.logos {
    width: 200px;
  }

  .login_img {
    text-align: center;
    position: absolute;
    display: block !important;
    top: 44px;
  }

  p.accopunt {
    top: 116px !important;
  }
  .right_side {
    padding: 1rem;
  }
  .modal-body.p-5 {
    padding: 10px !important;
  }
  label.form-check-label {
    font-size: 12px;
  }

  .top_section {
    margin-top: 8rem;
  }
  .remove_space_forgot {
    margin-top: 0rem !important;
  }
}

.verification-modal-inner img {
  width: 100px;
  margin: 0 auto 10px;
  display: block;
}
.verification-modal-inner h5 {
  text-align: center;
}
.justleft{
  justify-content: left !important;
}
.justifiit{
  justify-content: center;
}