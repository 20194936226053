tr.deactive {
    background-color: #ffd9dd;
  }
  
  .list_name {
    min-width: 90px;
  }
  
  .activedeactiveicon {
    font-size: 18px;
    line-height: normal;
    vertical-align: middle;
  }
  
  .profiledetailscls {
    background: #f2f2f2;
    padding: 8px 16px;
    border-radius: 50px;
    font-size: 14px;
    height: 40px;
  }
  
  .mobilalnumber {
    width: 100%;
    position: absolute;
    left: 104px;
    padding: 3px !important;
    border-left: none !important;
    border: none !important;
    height: 35px;
    margin-top: 2px;
  }
  
  .mobilalnumber:focus {
    width: 100%;
    position: absolute;
    left: 104px;
    padding: 0px !important;
    border-left: none !important;
  }
  
  .react-tel-input .form-control:focus {
    position: relative;
    font-size: 14px;
    letter-spacing: 0.01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px;
    margin-left: 0;
  
    border: 1px solid #cacaca;
    border-radius: 5px;
    line-height: 25px;
    height: 38px;
  }
  
  .react-tel-input .form-control {
    position: relative;
    font-size: 14px;
    letter-spacing: 0.01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px;
    margin-left: 0;
  
    border: 1px solid #cacaca;
    border-radius: 5px;
    line-height: 25px;
    height: 38px;
    width: 100% !important;
    outline: none;
  }
  
  // img.rounded-circle.mr-1.prop_img {
  //     width: 37px;
  //     height: 37px;
  // }
  
  .nowrap {
    white-space: nowrap;
  }
  
  .modal-dialog-md {
    max-width: 900px !important;
  }
  .tbbl-price-range {
    padding: 10px;
    border: 1px solid #7a7a7a;
    border-radius: 4px;
  }
  .tbl-shadow {
    background: #fff;
    border: 1px solid #918a8a;
    padding: 9px 10px 4px;
    border-radius: 4px;
  }
  .table-action-icon span {
    margin-right: 8px;
  }
  .table-action-icon span i {
    font-size: 12px;
  }
  .table-action-icon span i.fa.fa-trash {
    color: #c33b3b;
  }
  .table-action-icon span i.fa.fa-eye {
    color: #958b8b;
  }
  .table-action-icon span {
    margin-right: 8px;
    border: 1px solid #d5d5d5;
    border-radius: 5px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .table-action-icon {
    display: flex;
  }
  .pprofile1.add-truck-sect {
    padding: 0;
  }
  .add-truck-sect .title-head {
    padding: 13px 20px;
    border: #f2f2f2;
    border-bottom: 1px solid #efefef;
    margin: 0;
  }
  .add-truck-sect .title-head h4 {
    margin: 0;
  }
  .add-truck-sect .main-row {
    padding: 20px;
  }
  
  //gourav css
  
  .action_icons {
    display: flex;
    gap: 12px;
    align-items: center;
    cursor: pointer;
  }
  
  .action_icons i.material-icons {
    font-size: 17px;
    vertical-align: sub;
  }
  .active .react-switch-bg {
    background-color: #4c509e !important;
  }
  .action_icons .edit_icon i.fa.fa-eye {
    color: #948989;
  }
  .action_icons i.material-icons.edit {
    color: #494f9f;
  }
  