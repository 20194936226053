//  css by Manjinder
@media (min-width: 992px) {
  .first_dropdown .dropdown-menu {
    top: 100%;
    left: -80px;
    margin-top: 0.125rem;
  }
}
@media (max-width: 991px) {
  .first_dropdown .prodiv_cl.show {
    left: 0 !important;
  }
}

.first_dropdown .prodiv_cl.show {
  position: absolute !important;
  // left: 0 !important;
  width: fit-content;
}
/* CSS used here will be applied after bootstrap.css */

.dropdown {
  display: inline-block;
  margin-left: 20px;
  padding: 10px;
}

.glyphicon-bell {
  font-size: 1.5rem;
}

.notifications {
  min-width: 420px;
}

.notifications-wrapper {
  overflow: auto;
  max-height: 250px;
}

.menu-title {
  color: #000000bd;
  font-size: 13px;
  display: inline-block;
  margin-bottom: 3px;

}

.glyphicon-circle-arrow-right {
  margin-left: 10px;
}

.notification-heading,
.notification-footer {
  padding: 0px 10px;
  border-bottom: 1px solid #8080805c;
  display: flex;
  justify-content: space-between;
}
.notification-heading .btn-primary {

  padding: 0px 10px;
  border-radius: 3px;
  font-size: 12px;
  margin-bottom: 5px;

}

.dropdown-menu.divider {
  margin: 5px 0;
}

.item-title {
  font-size: 14px;
  white-space: nowrap;
  color: #000;
  padding-right: 3px;
}

.notifications a.content {
  text-decoration: none;
  background: #ccc;
  text-align: center;
  color: gray;
}

.notification-item {
  padding: 10px;
  margin: 5px;
  background: #cccccc3d;
  border-radius: 4px;
}

.noteimg{
  width: 25px;
  height: 25px;
  object-fit: cover;
  border-radius: 28px;
}
.nottime{
  font-size: 10px;
  color: gray;
  line-height: 8px;
  padding-top: 4px;
}
.notmess{
  font-size: 14px;
}
.messagep{
  line-height: 18px;
  font-size: 12px;
  color: #000000d1;
  text-align: start;
}
.notcount{
  position: absolute;
  top: 5px;
  text-align: center;
  height: 16px;
  right: 1px;
  width: 17px;
  max-width: 28px;
  background-color: #d63031;
  border-radius: 20px;
  color: white;
  border-style: solid;
  border-width: 1px;
  font-size: 9px;
  line-height: 14px;

}
.notifilayout{
  min-width: 340px;
  left: -318px !important ;
  max-width: 340px;
  top: 40px;
  z-index: 9999999;
}
.secdrop{
  padding-left: 5px !important;
  margin-left: 0 !important;
}
.secdrop2{
  padding-left: 10px !important;
  margin-left: 0 !important;
}
.page_new .dropdown{
  margin-left: 0 !important;
}