.searchForm {
  max-width: 300px;
}

.sidebar_new {
  display: flex;
  flex-direction: row;
  height: 100vh;
  position: fixed;
  transition: 0.8s ease;
  z-index: 9;
}
.inner_wraping {
  background: #494f9f;
  display: flex;
  justify-content: center;
  padding: 10px;
  text-align: center;
  width: 50px;
}
.big_sideswrap {
  background: #f5f5f5;
  border-right: 1px solid hsla(0, 0%, 81%, 0.973);
  position: relative;
  width: 230px;
}
.mai_data_full {
  background: #00218f0a;
  margin-left: auto;
  overflow-y: scroll;
  padding-left: 0;
  padding-right: 0;
  padding-top: 59px;
  transition: 0.4s ease;
  width: calc(100% - 280px);
}
.logo-img-n img {
  width: 30px !important;

  margin: 0 auto 20px auto;
  width: auto;
}
.small_sdies {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}
.small_sdies .ulclasssides {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style: none;
  padding: 0;
}
.ulclasssides li a {
  color: #fff;
  cursor: pointer;
}
.ulclasssides li a svg {
  width: 18px;
  height: 18px;
}
.ulclasssides li {
 
  margin: 5px 2px 18px;
}
.pulse_profile {
  animation: shadow-pulse 1s infinite;
  background: #1e90ff99;
  border-radius: 50%;
  color: #fff;
  font: 12px/100px Barlow Semi Condensed, sans-serif;
  height: 30px;
  letter-spacing: 1px;
  margin-left: 0;
  text-transform: uppercase;
  width: 30px;
}
img.rounded-circles {
  border-radius: 100%;
  width: 25px;
  height: 25px;
  margin: 0 auto;
  display: block;
}
.profile-img .dropdown-toggle::after {
  display: none;
}
.profile-img .dropdown {
  display: inline-block;
  margin-left: 0;
  padding: 0;
}
.main-wrappers {
  background-color: #fff;
  flex-direction: row;
}
.hegiht100 {
  height: 100vh;
}
.big_sideswrap {
  background: #f5f5f5;
  border-right: 1px solid hsla(0, 0%, 81%, 0.973);
  position: relative;
  width: 230px;
}
.headerlogo.collpase_data {
  border-bottom: 1px solid hsla(0, 0%, 81%, 0.973);
  height: 60px;
  padding: 15px;
}
.half_sides {
  margin-left: 50px;
  overflow-y: scroll;
  width: 100%;
}
.toogle_hide.big_sideswrap.sidebar_togle {
  display: none;
}
.icons-sidebar {
  display: flex;
}

.toggle-icon-n {
  display: none;
}
.toggle-show {
  display: block !important;
  cursor: pointer;
}
.name_menus {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fs-16 {
  font-size: 16px;
  font-weight: 600;
  margin-top: 0;
}
.side_title {
  margin-bottom: 0;
}
.itesm_list ul li a {
  cursor: pointer;
  font-size: 14px;
  margin: 12px 10px;
  padding: 6px;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 50px;
  text-decoration: none;
}
.itesm_list ul li svg {
  width: 26px;
  height: 26px;
  background: #efefef;
  padding: 5px;
  border-radius: 50%;
  color: #00000073;
}
.itesm_list li {
  list-style: none;
}
.itesm_list ul {
  padding: 0;
}
.header_sidebars {
  background: #fff;
  border-bottom: 1px solid hsla(0, 0%, 81%, 0.973);
  height: 60px;
  overflow: hidden;
  padding: 0 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
}
.name_s p {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}
.width-calc {
  width: calc(100% - 280px);
}
.h-60 {
  height: 60px;
}
.front-img img {
  width: 100%;
  max-width: 230px;
  margin: 0 auto;
  display: block;
}
.load-search h2 {
  text-align: center;
  font-size: 25px;
}
.load-search .home-searchbar {
  position: relative;
}
// .load-search .submit-btn {
//   position: absolute;
//   top: 115px;
//   left: 50%;
//   transform: translateX(-50%);
// }
.load-search .home-searchbar {
  display: block;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 5px 0px, rgba(0, 0, 0, 0.16) 0px 2px 10px 0px;
  border: 0.92px solid #e4e5e8;
  background: #fff;
  border-radius: 6px;
  max-width: 1060px;
  margin: 0 auto;
  padding: 24px 26px 24px;
}
.load-search .home-searchbar .input-group {
  width: 100%;
  min-height: 45px;
  background: #f7f7f7;
  z-index: 1;
  border-radius: 7px;
  
}
.job-sect-table i.fa.fa-sort {
  margin: 0 0 0 3px;
}
.load-search .home-searchbar input {
  border: 0;
  background: transparent;
  width: 91%;
  padding: 0 92px 0 0;
}
.load-search .home-searchbar .input-group-text{
  width: unset;
}
.submit-btns {
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
}
.mainareas {
  background: #ffffff;
  padding: 30px;
  height: calc(100vh - 64px) !important;
  overflow: auto;
}
.mainareas.load-main {
  background: #ffffff;
  padding: 40px;
  height: unset !important;
  overflow: auto;
  margin: 79px 0 0 0;
}
.load-search .home-searchbar .input-group path {
  stroke: rgb(82, 82, 82) !important;
}
.load-search .dark-btn {
  background: #3f559e;
  color: #fff;
  border: 1px solid #3f559e !important;
  width: 100%;
  padding: 6px 10px;
}
.das-box {
  border: 0.0375rem solid #c4c4c4;
  border-radius: 1rem;
  box-shadow: rgba(12, 0, 51, 0.1) 0px 0.25rem 0.5rem 0px;
  padding: 40px;
  text-align: center;
}
.das-box p.sub_heading {
  font-size: 18px;
  font-weight: 400;
  color: #898989;
  margin-bottom: 17px;
  margin-top: 17px;
}
.load-search .cards_sales {
  max-width: 1060px;
  margin: 40px auto;
}
// .select-load-width {
  
// }


.select-load-right .dropdown.addDropdown {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
}
.load-search .select-load-right .dropdown.addDropdown .btn{
  height: 43px;
  width: 100%;
}
.load-search .datepickerdropdown  > .btn-primary {
  height: 43px;
  width: 100%;
  text-align: left;
}
.load-search .datepickerdropdown > .btn-primary i {
  right: 10px;
  position: absolute;
  font-size: 12px;
  top: 50%;
  transform: translateY(-50%);
}
.load-search img.wallets_img {
  width: 90px;
  height: 90px;
  background: #ffffff;
  padding: 17px;
  border-radius: 50px;
  opacity: 0.8;
  box-shadow: rgba(27, 31, 14, 0.25) 0px -1px 55px -21px inset,
    rgba(0, 0, 0, 0.2) 0px 10px 28px -29px inset;
}
.bids-data {
  border: 1px solid #00000045;

  border-radius: 5px;
}

.bids-upper {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 14px;
}

.bids-middle {
  width: 32%;
  border-right: 1px solid #0000002b;
  padding-right: 3px;
}
.bids-right {
  width: 20%;
  padding-left: 30px;
}
.bids-bottom {
  margin: 0;
  border-right: 1px solid #0000002b;
  width: 48%;
  padding-left: 30px;
  padding-right: 20px;
  position: relative;
}
.bids-bottom .dotdiv{
    position: unset;
}
.bids-bottom ul {
  margin: 0;
  padding: 0;
}
.bids-middle h5 {
  font-size: 14px;
  color: #0000008a;
  display: flex;
}
.bids-middle h5 {
  font-size: 14px;
  color: #000;
  font-weight: 400 !important;
}
.bids-middle h5 span {
  width: 120px;
  color: #0000008a;
}
.bids-bottom ul li {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 8px 0;
  font-size: 15px;
  color: #000000a1;
}
.bids-upp-m {
  background: #f2f3f499;
  padding: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px dashed #00000021;
}
.bids-upp-m h5 {
  flex-direction: column;
  margin-bottom: 0;
  gap: unset;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  display: flex;
}
.bids-upp-m h5 span {
  font-size: 14px;
  color: #0000008a;
  margin-top: 2px;
}

.bids-upp-m p svg {
  width: 35px;
  height: 35px;
}
.bids-upp-m p {
  width: 20%;
  margin: 0 auto;
  text-align: center;
}
.bids-right h5 {
  font-size: 15px;
  text-transform: capitalize;
  color: #e60303;
}
.bid-p {
  font-size: 19px;
  font-weight: 500;
}
.bids-bottom h3 {
  font-size: 18px;
  line-height: 18px;
}
.bids-bottom ul li svg {
  transform: rotate(180deg);
  color: #0000005c;
  width: 20px;
  height: 20px;
  margin-right: 7px;
}
.bids-right h5.badge.badge-primary {
  width: 138px;
  height: 38px !important;
  margin: 10px 0 0 0 !important;
}
.view-detail {
  margin: 15px 0 0 0;
}
.view-detail a, .view-detail span {
  font-size: 13px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  color: #3c559b;
}
.view-detail a svg {
  width: 20px;
}
.mai_data_full.dashboard_full {
  width: calc(100% - 50px);
}
.ulclasssides li a:hover {
  color: #fff !important;
}
.ulclasssides li a.active {
  color: #474e9c;
  background: #fff;
  padding: 7px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
    rgba(0, 0, 0, 0.05) 0px 5px 10px;
  transition: 0.3s ease-in;
}
.bids-top h4 {
  margin: 0;
}
.bids-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ulclasssides li a.active svg {
  color: #494f9f;
}
.loc-flex {
  display: flex;
  width: 30%;
  justify-content: center;
}
.loc-flex img {
  width: 35px;
  margin: 0 13px 0 0;
}
.common-tbls.table > :not(:last-child) > :last-child > * {
  background: transparent;
  border-bottom: 0;
  padding: 0;
}
table.common-tbls {
  background: #fff;
  margin: 0;

  border: 0;
  border-bottom: 1px solid #00000040;
}
.common-tbls td {
  padding: 0;
  border: 0;
}
.tbl-new {
  border: 1px solid #00000040;
  padding: 10px;
}
.staff-inner {
  padding: 0 !important;
  margin: 0;
  display: flex;
  width: 90%;
  flex-wrap: wrap;
}
.staff-inner li {
  list-style: none;
  width: 25%;
}
.staff-action li {
  list-style: none;
}
.staff-inner li h5 {
  font-size: 14px;
}
.staff-action li h5 {
  font-size: 14px;
}
.staff-inner li p {
  margin: 0;
  font-size: 14px;
  line-height: 30px;
}
.staff-main {
  display: flex;
  width: 100%;
  padding: 10px;
  border-bottom: 3px solid #474e9c26;
}
.staff-inner li:nth-child(2) {
  width: 35%;
}
.staff-inner li:nth-child(4) {
  width: 15%;
}
.staff-listing {
  margin: 0 0 20px 0;
  box-shadow: rgba(99, 96, 99, 0.2) 0px -1px 8px -2px;
  border-radius: 5px;
  border: 1px solid #00000014;
}

ul.staff-action .dropdown.dotsbtn {
  position: unset;
  margin: 0;
  padding: 0;
}
ul.staff-action {
  width: 10%;
  padding: 0;
  margin: 0;
}
.white-head-inner .loc-flex {
  display: flex;
  width: 50%;
  justify-content: flex-start;
}
.white-head-inner .bids-upp-m {
  background: #474e9c14;
  padding: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #0000001a;
}
.white-head-inner .view-flex {
  display: flex !important;
  flex-direction: column;
}

.white-head-inner label.profileheddingcls {
  width: 100%;
  font-weight: 400 !important;
}
.edit-page-commons .white-head {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 9px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  background: #474e9c12;
}
.edit-page-commons .white-head .track-details h5 span {
  font-size: 14px !important;
  color: #0000008a;
  margin-top: 2px;
  display: block;
}
.edit-page-commons .view-flex .profiledetailscls {
  background: transparent;
  padding: 0;
  border-radius: 0;
  height: unset;
  font-size: 14px;
  color: #000;
  font-weight: 400 !important;
}
.steps-main .rs-steps {
  width: 100% !important;
  padding-left: 20px !important;
}
.steps-main .rs-steps-item-status-finish .rs-steps-item-icon-wrapper {
  border-color: #474e9c !important;
  color: #ffffff;
  background: #474e9c !important;
}
.steps-main .rs-steps-item-status-finish .rs-steps-item-tail,
.steps-main .rs-steps-item-status-finish .rs-steps-item-title::after {
  border-color: #454d99 !important;
}
.steps-main .rs-steps-vertical .rs-steps-item:not(:first-child) {
  margin-top: 0;
}
.steps-main .rs-steps-vertical .rs-steps-item-tail {
  top: 0 !important;
  border-left-style: dashed !important;
}
.steps-main .rs-steps-item-title {
  font-size: 14px;
  color: #000000c4;
}
.steps-main .rs-steps-item-description {
  margin-top: 0px;
  font-size: 14px;
}

.arrow-center img {
  width: 25px;
  margin: 0 15px;
}
.track-details .loc-flex img {
  background: #fff;
  padding: 5px;
  border-radius: 50px;
  box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 2px 0px;
}
.white-head .track-details h5 {
  margin: 0;
  font-size: 14px;
}

.edit-page-commons .profilelist {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.94px;
  color: #000000e3;
}
.track-details {
  display: flex;

  flex-direction: column;
  padding: 10px;
}
.track-details .loc-flex {
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}
.track-details .loc-flex span {
  display: block;
  font-size: 14px !important;
  color: #0000008a;
  margin-top: 2px;
  display: block;
}
.arrow-center img {
  transform: rotate(90deg);
}
.track-details .loc-flex img {
  background: #474e9c0f;
  padding: 12px;
  border-radius: 50px;
  box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 2px 0px;
  width: 64px;
  height: 64px;
}
.track-details h5 {
  margin: 0;
  font-size: 14px;
}
// .arrow-center img {
//     width: 35px;
//     margin: 10px 20px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
// }
.arrow-center {
  border-left: 1px dashed #474e9c7d;
  margin: 7px 34px;
  height: 56px;

  display: flex;
}
span.custom-toggle-btn {
  height: 100%;
  display: flex;
  margin: 14px 0 0 0;
}

.active .custom-toggle-btn .react-switch-bg {
  height: 20px !important;
  width: 45px !important;
  background: #494f9f !important;
}
.custom-toggle-btn .react-switch-bg {
  height: 20px !important;
  width: 45px !important;
}
.custom-toggle-btn .react-switch-handle {
  height: 13px !important;
  width: 13px !important;
  top: 3px !important;
  left: 2px !important;
}
.active .custom-toggle-btn .react-switch-handle {
  height: 13px !important;
  width: 13px !important;
  top: 3px !important;
  left: 0 !important;
}
span.badge.badge-pending {
  background: #e7e7e7;
  color: #50459d;
  font-weight: 500;
}
// .new-edit label{
//     background: #fff;
//     margin: -9px 0 0 19px;
//     position: absolute;
//     font-size: 12px;
//     padding: 0 4px;
// }
// .new-edit input, .new-edit select{
//     height: 44px;
// }
// .new-edit .col-md-6{
//     position: relative;
// }
.new-edit label {
  font-size: 14px;
  margin: 0 0 5px 0;
  letter-spacing: 0.96px;
  color: #000000a6;
}
.input-new-design {
  display: flex;
  border: 1px solid #ced4da;
  border-radius: 4px;
  height: 40px;
}
.input-icon {
  padding: 4px 16px;
  background: #474e9c12;
  color: #474e9c;

  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.input-new-design input {
  border: 0;
}
.input-new-design select {
  border: 0;
}
.new-edit .form-control:disabled,
.new-edit .form-control[readonly] {
  background-color: #0000001c;
  opacity: 1;
  border-radius: 0;
}
.new-edit .white-bg-main {
  background: #fff;
  border: 1px solid #efefef;
  // border-radius: 5px;
  // box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}
.new-edit .imagethumbWrapper img {
  width: 200px;
  border-radius: 50%;
  height: 200px;
  object-fit: cover;
  border: 1px solid #e3dada;
  padding: 4px;
  margin: 0 auto;
  display: block;
}
.new-edit .imagethumbWrapper {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 26px auto;
  display: block;
}
.new-edit .upload-img-icon {
  position: absolute;
  bottom: 5px;
  right: 33px;
}
.new-edit .upload-img-icon .btn {
  background: #474e9c;
}
.new-edit .upload-img-icon .btn svg {
  color: #fff;
}
.new-edit .white-head {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 9px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  background: #474e9c12;
}
.new-edit .profilelist {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.94px;
  color: #000000e3;
}
.profile-sect-n {
  background: #f2f2f8 !important;
  margin: 0;
}
.disable-input {
  text-align: center;
}
.new-edit .disable-input .form-control:disabled {
  background-color: #474e9c;
  opacity: 1;
  border: 0;
  text-align: center;
  color: #fff;
  border-radius: 4px;
}
.page-new.paginationWrapper.main-pagination {
  // justify-content: center;
  justify-content: space-between !important;
}
.page-new .dropdown.addDropdown.chnagesname {
  margin-left: 0 !important;
}
.action-btns a {
  border: 1px solid #474e9c47;
  padding: 9px 10px;
  border-radius: 3px;
  background: #474e9c0f;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
}
.action-btns a span{
  display: flex;
  align-items: center;
  justify-content: center;
}
.action-btns a span i {
  font-size: 14px;
  color: #0000006b;
}
.new-width {
  width: 100px !important;
  height: 100px;
  display: block;
  margin: 0 auto 20px auto;
}
.new-width img {
  width: 100%;
  height: 100%;
}
.user-info-bx {
  text-align: center;
}
.email-text {
  font-size: 14px;
}
.user-info-bx h4 {
  margin: 10px 0 4px 0 !important;
  line-height: 20px;
  font-size: 18px;
}
.profile-img .dropdown-menu.show {
  border: none;
  cursor: pointer;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.profile-img ul li a.dropdown-item {
  color: #000000b0;
}
.profile-img ul li {
  margin: 0;
}
.profile-upper {
  background: #efefef38;
  margin: 0 0 20px 0;
  border: 1px solid #00000012;
  padding: 20px;
  border-radius: 10px;
  border-left: 2px solid #474e9c4f;
}
.portal-new {
  padding: 60px 0 !important;
}
.portal-new .profile-tabs-left {
  background: #474e9c00;
}
.notify-img img {
  width: 160px;
  margin: 12px auto;
  display: block;
}
.mainareas .no-data img {
  width: 260px;
}
.notify-img h4 {
  text-align: center;
  margin: 0;
}
.notification-main {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: 1px solid #0000001c;
}
.notify-bottom .notification-item {
  background: transparent;
}
// .notification-main {
//     background: #474e9c12;
//     border: 1px solid #efefef;
//     box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
// }
.notify-bottom {
  background: #fff;
}
.notify-bottom .noteimg {
  width: 25px;
  height: 25px;
  object-fit: cover;
  border-radius: 28px;
}
.notify-bottom a {
  text-decoration: none;
}
.notify-bottom .notifications-wrapper h4 {
  margin: 0 15px 22px 0;
  color: #474e9c;
}
.notify-bottom .notifications-wrapper h4::after {
  content: '';
  width: 92px;
  height: 2px;
  background-color: #474e9c;
  display: block;
}
.notify-bottom .notifications-wrapper {
  overflow: auto;
  max-height: unset;
  border-bottom: 1px dashed #474e9c7a;
  margin: 0 12px;
  padding-bottom: 12px;
}
.notify-upper {
  padding: 3px 0 20px 0;
  border-bottom: 2px solid #474e9c24;
  background: #474e9c0a;
}
.notify-bottom .notifications-wrapper h4 {
  padding: 15px;
}
.staff-width li {
  width: 18%;
}
.staff-width li:first-child {
  width: 25%;
}
.staff-width li:last-child {
  width: 10%;
}
.staff-width li:nth-child(2) {
  width: 28%;
}
.listing-width.staff-width li:first-child {
  width: 15%;
}
.listing-width.staff-width li:nth-child(2) {
  width: 25%;
}
.listing-width.staff-width li{
  width: 12%;
}
.listing-width.staff-width li:nth-child(4) {
  width: 14%;
}
.listing-width.staff-width li:nth-child(7) {
  width: 10%;
}
.new-width img.uploadimage {
  margin: 0 auto;
}
.active .custom-toggle-btn .react-switch-bg {
  background: #494f9f !important;
}
.staff-listing .custom-toggle-btn .react-switch-bg {
  background: #474e9c61 !important;
}
.position-person {
  padding: 3px 20px;
  background: #474e9c1a;
  width: fit-content;
  margin: 7px auto 0 auto;
  font-size: 14px;
  border-radius: 33px;
  color: #000000a3;
}
.height-set .white-bg-main {
  background: #fff;
  border: 1px solid #efefef;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-items: stretch;
  height: 100%;
}
.carrier-width .staff-inner {
  width: 80%;
}
.carrier-width ul.staff-action {
  width: 20%;
}
.notification-m .notcount {
  position: absolute;
  top: -2px;
  text-align: center;
  height: 14px;
  right: -4px;
  width: 14px;
  max-width: 28px;
  background-color: #d63031;
  border-radius: 20px;
  color: white;
  border-style: solid;
  border-width: 1px;
  font-size: 9px;
  line-height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dark-btn.btn-width {
  width: fit-content;
  padding: 6px 16px !important;
}
.bids-upper .badge-success {
  background: #3c559b1c;
  color: #0009;
  padding: 3px 8px;
  border-radius: 3px;
  width: fit-content;
}
span.badge-danger {
  background: #ff000038;
  color: #00000082;
}
.badge-primary {
  background: #474e9c1c;
  color: #0000006e;
}
.accept-user i {
  color: #3c559b !important;
}
.reject-user i {
  color: #c1711a !important;
}
.row.search-truck {
  background: #f2f2f8;
  margin: 0;
}
.search-truck img {
  width: 73px;
  margin: 0 auto;
  display: block;
  background: #fff;
  padding: 9px;
  border-radius: 50%;
}
.bids-top span {
  background: #434a941a;
  width: 44px;
  height: 35px;
  display: inline-block;
  text-align: center;
  margin: 0 0 0 10px;
  border-radius: 4px;
  color: #0000006e;
  font-size: 18px;
  line-height: 30px;
  padding: 2px;
}
.bids-top h4 {
  display: flex;
  align-items: center;
}
.rs-steps-item-icon-wrapper {
  background: #fff !important;
}
.height-set {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-items: stretch;
}
.steps-scroll {
  height: 200px;
  overflow: auto;
}
.rs-steps-item-status-process .rs-steps-item-icon-wrapper {
  color: #545461 !important;
}
.sidebar_active {
  background: #5146a0 !important;
  color: #fff !important;
}
.sidebar_active:hover {
  color: #fff !important;
}
.profile-img ul li a.dropdown-item:hover {
  color: #333030 !important;
}
.profile-img ul li a.dropdown-item:active {
  color: #fff !important;
}
.profile-img .dropdown-menu.show svg {
  width: 16px;
}
.active .custom-toggle-btn .react-switch-bg {
  background: #5146a0 !important;
}
.deactive .custom-toggle-btn .react-switch-bg {
  background: #474e9c61 !important;
}
.staff-new {
  width: 85%;
}
ul.staff-action {
  width: 10%;
}
.bids-bottom span.destspan svg {
  margin: -1px 0 0 0;
  transform: rotate(180deg);
  color: #0000005c;
  width: 20px;
  height: 20px;
  margin-right: 7px;
}
// .bids-bottom .destspan, .bids-bottom .destspan span {
//     max-width: 337px;
//     -webkit-line-clamp: 1;
//     -webkit-box-orient: vertical;
//     overflow: hidden;
//     font-size: 15px;
//     color: #000000a6;
//     line-height: 26px;
//     display: -webkit-box !important;
// }
.bids-bottom .destspan, .bids-bottom .destspan span {
font-size: 15px;
color: #000000a6;
line-height: 26px;
min-width: unset !important;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
max-width: 337px;
width: 100%;
}
.badge-w {
    height: 36px;
    padding: 8px 15px;
}
span.tableficon {
  margin: 0 0 0 10px;
}
.bids-right .text-primary {
  color: #474e9c !important;
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline !important;
}
.w-max {
  width: 200px;
}
.bids-new {
  background: #fff;
}
.miles-color {
  color: #0000008a;
  font-size: 13px;
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #000000a6 !important;
  background-color: #573aa140 !important;
  border: 0 !important;
}
.driver-fail {
    background: #474e9c12;
    text-align: center;
    border-radius: 5px;
    width: fit-content;
    margin: 24px auto;
    padding: 2px 24px;
}
.all-stops-list {
  text-align: end;
  width: -moz-fit-content;
  width: fit-content;
  margin: 19px 0 0 auto;
  color: #474e9c;
  font-size: 13px;
  text-decoration: underline !important;
  cursor: pointer;
  position: absolute;
  right: 14px;
  bottom: -16px;
  display: block;
}
.all-stops-tool{
    margin: 0;
    padding: 0;
}
.all-stops-tool li {
    list-style: none !important;
    display: flex;
    margin: 0 0 10px 0;
    font-size: 14px;
}
.all-stops-tool span.tableficon i {
    background: #fff;
    color: #5146a0 !important;
}
.all-stops-tool span.tableficon {
    margin: 0 10px 0 0;
}

.doc-modal .documentation_modal label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px dashed #00000033 !important;
    width: 100%;
    padding: 22px;
    background: #f2f3f499;
}
.doc-modal .documentation_modal label i {
    margin: 0px 0 13px 0;
    color: #00000070;
}
.doc-modal .text-success{
    text-align: center;
    margin: 5px  0 0 0;
}
.doc-modal .imagethumbWrapper.product-images img {
    border: 0;
    width: 38px !important;
    height: 38px !important;
}
.doc-modal .lis_times {
    width: 16px !important;
    height: 16px !important;
    left: unset !important;
    right: -4px !important;
}
.bids-bottom .dropdown.dotsbtn {
    position: unset;
    top: -13px;
    right: 0;
}
.edit-page-commons .white-bg-main > .row {
    padding: 15px 10px;
  }
  .edit-page-commons .view-flex .profileheddingcls {
    width: 125px;
  }
  .license-upload .licenceimgTwoProd img {
    border: 0 !important;
    width: 45px !important;
    height: 45px !important;
}
.license-upload  .lis_times {
    top: -4px !important;
    left: 29px !important;
    width: 16px !important;
    height: 16px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.license-upload label {
    border: 1px dashed #00000047;
    padding: 10px;
}  
.license-upload label {
    border: 1px dashed #00000047;
    padding: 10px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
} 
.license-upload label i{
    margin: 0 10px 0 0;
}
.bids-bottom .dotdiv .dropdown {
    padding: 0 0 0 10px;
}
.trailer {
    display: flex;
    align-items: center;
}
.address-w {
  width: 70%;
}
// Badges
.accepted_badgeNew {
  background: #0ba02c1f !important;
  color: #0ba02c !important;
}

.pending_badgeNew {
  background: #474e9c1c !important;
  color: #0000006e !important;
}
.awarded_badgeNew {
  color: #4a4c9f !important;
  background: #4a4c9f3d !important;
}
.delivered_badgeNew {
  background: #e7f6ea !important;
  color: #0ba02c !important;
}
.search-n.loadCross .search_cross1 {
  position: absolute !important;
  top: 14px;
  color: grey;
  font-size: 11px;
  right: 30px !important;
}
.profile-sect-n .imagethumbWrapper .upload-img-icon .fa-times {
  color: #ffffff;
}
div#loader {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
  background: transparent !important;
}
.lds-ring {
  position: absolute;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #8080803d;
  border-radius: 10px;
  padding: 10px;
}
.notes-r {
  font-size: 13px !important;
}
.dots-end {
  text-align: end;
  margin: 0 0 0 auto;
  padding: 0 0 8px 0 !important;
}
.dots-end.dotsbtn .btn{
  margin-right: 0 !important;
}
.borer-c button {
  height: 38px;
  border: 1px solid #00000036 !important;
  border-radius: 4px !important;
}
.borer-c button:hover{
  border: 1px solid #00000036 !important;
  border-radius: 4px !important;
}
.undo i {
  font-size: 14px;
}
.license-upload span {
  display: none !important;
}
.load-search .home-searchbar label {
  margin: 0 0 5px 0;
}
.new-table .action_icons span, .new-table .action_icons a {
  border: 1px solid #474e9c47;
  padding: 8px 10px;
  border-radius: 3px;
  background: #474e9c0f;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 36px;
  height: 34px;
}

.new-table .table thead th {
  background: #474e9c1f !important;
}
.new-table .table th,.new-table .table td {
  padding: 0.75rem;
  vertical-align: middle;
  background: #e3d9d91f;
  font-size: 13px;
}
.new-table .table {
  border: 1px solid #00000014 !important;
}
.new-table tr{
  cursor: pointer;
  transition: .3s all ease-in;
}
.trucks-listing .staff-inner {
  width: 100%;
}
.trucks-listing .staff-inner li{
  width:32%;
}
img.pageLoader {
  width: 38px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.driver-f img {
  width: 100px;
  margin: 0 auto 20px auto;
  display: block;
}
.btn-set i {
  font-size: 13px;
}
ul.staff-inner.notes-view {
  width: 100%;
}
.notes-view.staff-inner li:nth-child(4) {
  width: 25%;
}
.notes-view.staff-inner li:nth-child(2) {
  width: 25%;
}
.result-set label {
  width: 100px;
  height: 40px;

  position: relative;
}
.result-set input {
  visibility: hidden;
  width: 100px;
  height: 40px;
  position: absolute;
  left: 0;
}
.result-set i{
  font-size: 13px;
}
.staff-main-new ul.staff-action {
  width: 15%;
}
.approved-carrier .staff-inner {
  width: 80%;
}
.approved-carrier  ul.staff-action {
  width: 20%;
}
.width-add {
  width: 60%;
}
.dots-end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 0 0 0 auto !important;
}
.width-f {
  width: 64% !important;
}
.stops-down-arrow {
  background: #474e9c26;
  padding: 6px 9px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  width: -moz-fit-content !important;
  width: fit-content !important;
  transition: .3s all ease-in;
}
.new-table tr{
  cursor: pointer;
  transition: .3s all ease-in;
}
.stops-down-arrow i {
  font-size: 17px;
  color: #474e9c;
  
}
.reject-img {
  text-align: center;
}
.reject-img img {
  width: 70px;
}
.reject-img img {
  margin: 0 0 15px 0;
}
.disabled_icon {
  pointer-events: none;
  cursor: none !important;
}
.drop-c .filterbt {
  display: flex;
  align-items: center;
}
.drop-c .dropdown-toggle::after {
  cursor: pointer;
  margin-left: 7px;
  right: 12px;
  position: absolute;
}
.itesm_list {
  overflow-y: auto;
  height: 100%;
}
.notify-bottom .notification-item {
  background: #474e9c0a;
  padding: 10px;
  margin: 12px 0 !important;
  border: 1px solid #0000001a;
}
.no-data.notify-no-data {
  height: 500px;
}
.text_underline{
  font-weight: 600;
  text-decoration: underline;
}
@media only screen and (max-width: 1440px) {

  .load-search .home-searchbar .input-group-text {
    width: unset;
}
.listing-width.staff-width li {
  width: 50% !important;
  margin: 0 0 18px 0 !important;
}
.staff-inner li h5 {
  font-size: 14px;
  margin: 0 0 0px !important;
}
}
// .carrier-width .staff-inner li:nth-child(2) {

//     width: 20%;
// }
@media only screen and (max-width: 1299px) {
  // .edit-page-commons .view-flex .profileheddingcls {
  //   width: 110px;
  // }
  // .edit-page-commons  .col-md-6.mb-3.view-flex,.edit-page-commons  .col-md-12.mb-3.view-flex {
  //     display: flex !important;
  //     flex-direction: column;
  // }
  .white-bg-main .badge {
    width: fit-content;
  }
  .new-edit .imagethumbWrapper img {
    width: 150px;

    height: 150px;
  }
  .new-edit .imagethumbWrapper {
    position: relative;
    width: 160px;
    height: 160px;
    margin: 49px auto;
    display: block;
  }
  .bids-bottom {
    padding-left: 12px;
    padding-right: 12px;
  }
  .edit-page-commons .view-flex .profiledetailscls {
    font-size: 13px;
    height: auto;
}
.bids-middle {
    width: 36%;
}
.bids-bottom {
    width: 44%;
}
.address-w {

    margin: 0 0 10px 0 !important;
}
.address-w {
    width: 65%;
}
.edit-page-commons .addressWidth {
    min-width: 110px;
}
}
@media only screen and (max-width: 1199px) {
  .bids-middle h5 {
    gap: unset;
    flex-direction: column;
    margin-bottom: 15px;
  }
  .bids-middle h5 .badge {
    width: fit-content !important;
  }
  .bids-middle h5 span {
    width: 100%;
    color: #0000008a;
    margin: 0 0 4px 0;
  }
  .track-details .loc-flex img {
    padding: 7px;
    width: 47px;
    height: 47px;
  }
  .arrow-center {
    margin: 7px 23px;
  }
  .height-set {
    margin: 10px 0;
  }
  .bids-right {

    padding-left: 15px;
}
.edit-page-commons .view-flex .profiledetailscls{
    font-size: 13px !important;
  }
  .address-w {
    width: 60%;
}
.flex-col-c {
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: flex-start !important;
}
.flex-col-c .bids-top{
  margin-bottom: 15px;
}
.staff-main-new {
  flex-direction: column;
}
.staff-new {
  width: 100%;
}
.staff-main-new ul.staff-action {
  width: 30%;
  margin: 20px 0 0 0;
}
.flex-set-w {
  flex-direction: column;
  align-items: flex-start !important;
}
.flex-set-w .bids-top{
  margin-bottom: 15px;
}
.staff-inner li {
  width: 50%;
  margin: 9px 0;
}
.staff-action li h5 {
  font-size: 14px;
  display: none;
}
.staff-inner {
  width: 84%;
}
ul.staff-action {
  width: 16%;
}
.staff-width li:first-child {
  width: 50%;
}

.staff-width li:nth-child(2) {
  width: 50%;
}
.staff-inner li:nth-child(4) {
  width: 50%;
}
.carrier-width .staff-inner {
  width: 72%;
}
.carrier-width ul.staff-action {
  width: 28%;
  display: flex;
  justify-content: flex-end;
}
.staff-main-new {
  flex-direction: unset;
}
.staff-main-new ul.staff-action {
  width: 30%;
  margin: 0;
}
.approved-carrier ul.staff-action {
  width: 30%;
}
.listing-width.staff-width li:first-child{
width: 50%;
}
.listing-width.staff-width li:nth-child(2){
width: 50%;
}
}
@media only screen and (max-width: 1024px) {
  .bids-bottom {
    padding-left: 10px;
  }
  .bids-right {
    padding-left: 10px;
  }
  .staff-inner li {
    width: 50%;
    margin: 9px 0;
  }
  .staff-action li h5 {
    font-size: 14px;
    display: none;
  }
  .staff-inner {
    width: 84%;
  }
  ul.staff-action {
    width: 16%;
  }
  .staff-width li:first-child {
    width: 50%;
  }

  .staff-width li:nth-child(2) {
    width: 50%;
  }
  .staff-inner li:nth-child(4) {
    width: 50%;
  }
  .carrier-width .staff-inner {
    width: 72%;
  }
  .carrier-width ul.staff-action {
    width: 28%;
    display: flex;
    justify-content: flex-end;
  }
  .staff-main-new {
    flex-direction: unset;
  }
  .staff-main-new ul.staff-action {
    width: 30%;
    margin: 0;
  }
  .approved-carrier ul.staff-action {
    width: 30%;
}
.listing-width.staff-width li:first-child{
  width: 50%;
}
.listing-width.staff-width li:nth-child(2){
  width: 50%;
}
}
@media only screen and (max-width: 991px) {
  .bids-middle {
    width: 100%;
    border-right: 0;
  }
  .bids-bottom {
    width: 66%;
    padding-left: 0;
    margin-top: 25px;
  }
  .bids-right {
    width: 34%;
    padding-left: 0;
    margin-top: 25px;
  }
  .bids-middle h5 {
    flex-direction: row;
  }
  .bids-middle h5 span {
    width: 140px;
  }
  .bids-middle {
    width: 100%;
    border-right: 0;
    padding-bottom: 25px;
    border-bottom: 1px solid #00000026;
  }
  .load-search .home-searchbar .input-group {
    width: 100%;
    margin: 5px 0px;
    min-height: 48px;
  }
  .load-search .submit-btn {
    position: unset;
    transform: unset;
    margin: 10px 0 0 0;
  }
  .load-search .submit-btn{
    width: 30%;
  }
  .staff-inner li {
    width: 100%;
  }
  .staff-width li:nth-child(2) {
    width: 100%;
  }
  .staff-width li:first-child {
    width: 100%;
  }
  .staff-inner li:nth-child(4) {
    width: 100%;
  }
  .staff-inner {
    width: 78%;
  }
  .staff-new {
    width: 76%;
  }
  .load-search .home-searchbar span#basic-addon1 {
    font-size: 12px;
    padding: 0 7px 0 7px;
}
.trucks-listing .staff-inner li {
  width: 100%;
}
.listing-width.staff-width li:first-child{
  width: 100%;
}
.listing-width.staff-width li:nth-child(2){
  width: 100%;
}
.bids-upp-m h5 {

  font-size: 16px !important;
}
}
@media only screen and (max-width: 767px) {
  .bids-bottom {
    width: 100%;
    padding-left: 0;
    margin-top: 25px;
    border-right: 0;
  }
  .bids-right {
    width: 100%;
    padding-left: 0;
    margin-top: 25px;
    text-align: left !important;
    border-top: 1px solid #00000026;
    padding-top: 25px;
  }
  .view-detail a,
  .view-detail span {
    justify-content: flex-start;
  }
  .status-p {
    justify-content: flex-start !important;
  }
  .bids-middle h5 {
    flex-direction: column;
    display: flex;
  }
  .bids-upp-m {
    flex-direction: column;
  }
  .bids-upp-m h5 {
    width: 100%;
  }
  .bids-upp-m h5 span {
    display: block;
  }
  .bids-upp-m p svg {
    width: 35px;
    height: 35px;
    transform: rotate(90deg);
  }
  .bids-upp-m p {
    width: 100%;
    margin: 14px 0;
    text-align: left;
  }
  .bids-right h5.badge.badge-primary {
    width: 138px;
    height: 38px !important;
    margin: 10px 0 0 auto;
  }
  .track-details .loc-flex img {
    padding: 7px;
    width: 50px;
    height: 50px;
  }
  .arrow-center {
    margin: 7px 24px;
    height: 56px;
  }
  .staff-inner {
    width: 75%;
  }
  ul.staff-action {
    width: 25%;
  }
  .loc-flex {
    width: 100%;
  }

  .sidebar_togle {
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    z-index: 999999;
    transition: 0.3s all ease-in;
  }
  .mai_data_full {
    width: calc(100% - 50px);
    transition: 0.3s all ease-in;
  }
  .mainareas {
    padding: 20px;
  }
  .bids-upp-m h5 {
    font-size: 16px !important;
  }
  .header_sidebars {
    z-index: 7;
  }
  .mainareas .no-data img {
    width: 184px;
  }
  .bids-upp-m p svg {
    width: 35px;
    height: 25px;
  }
  .loc-flex img {
    width: 28px;
  }
  .bids-middle h5 {
    margin: 0 0 18px 0 !important;
  }
  .bids-bottom {
    .staff-new {
      width: 85%;
    }
    padding-right: 0 !important;
  }
  .bids-bottom ul li {
    font-size: 13px;
  }
  .staff-main {
    flex-direction: column;
  }
  ul.staff-action {
    width: 100%;
    margin: 20px 0 0 0;
  }
  .notify-bottom .notifications-wrapper h4 {
    font-size: 17px;
  }
  .notify-bottom .notifications-wrapper h4::after {
    width: 53px;
  }
  .header_sidebars .width-calc {
    width: 100%;
  }
  .white-head-inner .bids-upp-m {
    justify-content: flex-start;
    align-items: flex-start;
   
}
.white-head-inner .loc-flex {
  display: flex;
  width: 100%;
  margin: 10px 0;
}
.all-stops-list {
  bottom: -14px;

}
.inner-notify {
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: flex-start !important;
}
}

